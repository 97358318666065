import React from 'react';
import styles from './Heading.module.scss';

export default function Heading({section}) {
  return (
    <div className={styles.heading}>
      <div className={styles.content}>
        <h2>{section.headline}</h2>
      </div>
    </div>
  );
}
