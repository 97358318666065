export const SET_LOADING = 'SET_LOADING';

export default (
  state = {
    loadCount: 0,
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_LOADING:
      const count = state.loadCount + (action.loading ? 1 : -1);
      return {
        ...state,
        loading: count > 0,
        loadCount: count
      };
    default:
  }
  return state;
};
