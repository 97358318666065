import React from 'react';
import styles from './Footer.module.scss';
import {Link} from 'react-router-dom';
import logo from '../assets/logo.svg';
import moment from 'moment';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <Link to="/"><img src={logo} width={160} alt="Zebra Print Management" /></Link>
        <address>1-3 Trinity Park, Pilgrim Way, Stanningley Road, Leeds LS28 6LU</address>
        <p>
          <span>T.&nbsp;</span><a href="tel:01132408383">0113 240 8383</a>
          &nbsp;&nbsp;<br className={styles.break} />
          <span>E.&nbsp;</span><a href="mailto:sales@zebraprintmanagement.co.uk">sales@zebraprintmanagement.co.uk</a>
        </p>
        <ul className={styles.links}><li><Link to="/privacy-policy">Privacy &amp; Cookie Policy</Link></li></ul>
        <p>Copyright &copy; {moment().format('YYYY')}. Zebra Print Management</p>
      </div>
    </footer>
  );
}
