import React, {useEffect, useRef, useState} from 'react';
import styles from './Contact.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import firebase from '../firebase';
import sha1 from 'sha1';
import validator from 'email-validator';
import {setContactComplete, setContactStarted} from '../store/actions/analytics';
import {useDispatch} from 'react-redux';

const SECRET = 'O75pU0S28V787u49oD3p35Zd1YSd620r36892O7l';

export default function Contact({section}) {
  const [initialised, setInitialised] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);

  const initialising = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialising.current) {
      initialising.current = false;
      setTimeout(() => setInitialised(true),1000);
    }
  }, [initialised]);

  useEffect(() => {
    if (document) {
      document.body.setAttribute('class', open ? 'no-scroll' : '');
    }
    if (open) {
      dispatch(setContactStarted('Floating Button'));
    }
  }, [open]);

  useEffect(() => {
    if (sent) {
      dispatch(setContactComplete());
    }
  }, [sent]);

  const handleSubmit = e => {
    e.preventDefault();
    const errorList = {};
    if (!name) {
      errorList.name = 'Please enter your name';
    }
    if (!email) {
      errorList.email = 'Please enter your email address';
    } else if (!validator.validate(email)) {
      errorList.email = 'Please enter a valid email address';
    }
    if (!message) {
      errorList.message = 'Please enter a message';
    }
    setErrors(errorList);
    if (Object.keys(errorList).length < 1) {
      setSending(true);
      const sent = moment().format('YYYY-MM-DD HH:mm:ss');
      const token = sha1(
        `${name}-${email}-${phone}-${message}-${moment(sent).format(
          'DDMMYYYYHHmmss',
        )}-Zebra-${SECRET}`,
      );
      const functions = firebase.functions();
      // To use local emulator
      // functions.useFunctionsEmulator('http://localhost:5001');
      functions
        .httpsCallable('sendMessage')({
          name,
          email,
          phone,
          message,
          sent,
          token,
        })
        .then(response => {
          setSending(false);
          if (response.data.status === 'ok') {
            setSent(true);
          } else {
            setError(section.error);
          }
        })
        .catch(() => {
          setSending(false);
          setError(section.error);
        });
    }
  }

  return (
    <div className={`${styles.holder} ${initialised ? styles.initialised : ''} ${open ? styles.open : ''}`}>
      <div className={styles.contactButton} onClick={() => setOpen(!open)}>{section.title}</div>
      <div className={styles.contact}>
        <div className={styles.container}>
          <div className={styles.closeButton} onClick={() => setOpen(!open)}><FontAwesomeIcon icon={faTimes} size="2x" /></div>
          <h3>{section.title}</h3>
          {sent ? <p>{section.thanks}</p> : <>
            <p className={error ? styles.generalError : ''}>{error ? error : section.prompt}</p>
            <form className={sending ? styles.sending : ''} onSubmit={handleSubmit}>
              <div className={`${styles.field} ${errors.name ? styles.fieldError : ''}`}>
                <label htmlFor="name">Your name:</label>
                <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} />
                <p className={styles.error}>&nbsp;{errors.name}&nbsp;</p>
              </div>
              <div className={`${styles.field} ${errors.email ? styles.fieldError : ''}`}>
                <label htmlFor="email">Your email address:</label>
                <input id="email" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                <p className={styles.error}>&nbsp;{errors.email}&nbsp;</p>
              </div>
              <div className={`${styles.field} ${errors.phone ? styles.fieldError : ''}`}>
                <label htmlFor="phone">Your telephone number:</label>
                <input id="phone" type="text" value={phone} onChange={e => setPhone(e.target.value)} />
                <p className={styles.error}>&nbsp;{errors.phone}&nbsp;</p>
              </div>
              <div className={`${styles.field} ${errors.message ? styles.fieldError : ''}`}>
                <label htmlFor="message">Your message:</label>
                <textarea id="message" value={message} onChange={e => setMessage(e.target.value)} />
                <p className={styles.error}>&nbsp;{errors.message}&nbsp;</p>
              </div>
              <div className={styles.field}>
                <button onClick={handleSubmit}>{sending ? 'Sending...' : 'Send'}</button>
              </div>
            </form>
          </>}
        </div>
      </div>
    </div>
  )
}
