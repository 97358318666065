import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadImage} from './store/actions/images';


export function useImage(path) {
  const images = useSelector(state => state.images.loaded);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(() => {
    const filtered = images.filter(i => i.path === path);
    if (filtered.length > 0) {
      setImage(filtered[0].url);
    } else {
      if (!loaded.current) {
        loaded.current = true;
        dispatch(loadImage(path));
      }
    }
  }, [images, path, dispatch]);

  return image;
}


export function useBackgroundImage(path) {
  const image = useImage(path);
  return image ? {backgroundImage: `url('${image}')`} : {};
}
