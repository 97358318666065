import React from 'react';
import styles from './Banner.module.scss';
import {useBackgroundImage} from '../hooks';

export default function Banner({section}) {
  const image = useBackgroundImage(section.background);
  let list = null;
  if (section.list) {
    list = (
      <ul className={styles.list}>{section.list.map((item, index) => <li key={index}>
        <span><span className={styles.bullet}>&bull;</span>{item}</span></li>)}</ul>
    )
  }
  return (
    <div className={styles.banner} style={image}>
      <div className={styles.container}>
        <h1>{section.headline.split('\n').map((item, index) => <span key={index}>{index !== 0 ?
          <br/> : null}{item}</span>)}</h1>
        <p>{section.body}</p>
        {list}
      </div>
    </div>
  )
}
