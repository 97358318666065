import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
const config = {
  apiKey: "AIzaSyCRW3iIhmyS99B_PKa-6H5AwHSNYrLZq-w",
  authDomain: "zebra-website-ae48c.firebaseapp.com",
  databaseURL: "https://zebra-website-ae48c.firebaseio.com",
  projectId: "zebra-website-ae48c",
  storageBucket: "zebra-website-ae48c.appspot.com",
  messagingSenderId: "924516640297",
  appId: "1:924516640297:web:839b8b307adea7e322de38",
  measurementId: "G-J19C1MC06J"
};
firebase.initializeApp(config);
if (typeof window !== 'undefined') {
  firebase.analytics();
}

if (process.env.NODE_ENV === 'development') {
  firebase
    .app()
    .functions()
    .useFunctionsEmulator('http://localhost:5001');
  firebase.app().firestore().settings({host: 'localhost:8080', ssl: false});
} else {
  firebase.app().functions();
}
export default firebase;
