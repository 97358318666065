export const SET_IMAGE = 'SET_IMAGE';

export default (
  state = {
    loaded: [],
  },
  action,
) => {
  switch (action.type) {
    case SET_IMAGE:
      return {
        ...state,
        loaded: [...state.loaded, {path: action.path, url: action.url}]
      };
    default:
  }
  return state;
};
