export const SET_PAGE = 'SET_PAGE';

export default (
  state = {
    page: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      };
    default:
  }
  return state;
};
