import {combineReducers} from 'redux';
import analytics from './analytics';
import general from './general';
import images from './images';
import pages from './pages';

const rootReducer = combineReducers({
  analytics,
  general,
  images,
  pages
});

export default rootReducer;
