import {asyncComponent} from '@jaredpalmer/after';
import React from 'react';

export default [
  {
    path: '/*',
    exact: true,
    component: asyncComponent({
      loader: () => import('./App'),
      chunkName: 'Main',
      Placeholder: () => <></>,
    }),
  }
];
