import React, {useEffect, useState} from 'react';
import styles from './Header.module.scss';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/logo.svg';
import {isIE} from 'react-device-detect';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    let handleScroll = null;
    if (!isIE) {
      handleScroll = () => {
        setScrolled(window.pageYOffset >= 80);
      };
      if (window) {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
      }
    }
    return () => {
      if (handleScroll && window) {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, []);

  return (
    <header className={`${styles.header} ${scrolled ? styles.scrolled : ''}`}>
      <div className={styles.container}>
        <h1 className={styles.logo}><Link to="/"><img src={logo} alt="Zebra Print Management" /></Link></h1>
        <div className={styles.spacer}/>
        <a className={styles.phone} href="tel:01132408383">
          <span><FontAwesomeIcon icon={faPhone} size="xs" />0113 240 8383</span>
        </a>
      </div>
    </header>
  );
}
