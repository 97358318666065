import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {setScreen} from '../store/actions/analytics';

export default function Analytics() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const log = () => {
      dispatch(setScreen(window.location.pathname));
    }
    log();
    history.listen(() => log());
  }, [history, dispatch]);
  return null;
}
