import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import styles from './App.module.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import './index.scss';
import Banner from './sections/Banner';
import Contact from './sections/Contact';
import Content from './sections/Content';
import Heading from './sections/Heading';
import Split from './sections/Split';
import {loadPage, loadPagePromise, setPage} from './store/actions/pages';

const sectionMap = {
  banner: Banner,
  contact: Contact,
  content: Content,
  heading: Heading,
  split: Split,
};

function App({match, history}) {
  const page = useSelector(state => state.pages.page);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPage(match.url));
  }, [match.url]);

  useEffect(() => {
    if (page && page.redirect) {
      history.push(page.redirect);
    }
  }, [page]);

  if (page) {
    return (
      <>
        <Helmet>
          <title>{page.title}</title>
        </Helmet>
        <div className={styles.container}>
          <Header/>
          <div className={styles.body}>
            {page.sections.map((section, index) => {
              const Component = sectionMap[section.type];
              if (Component) {
                return <Component key={index} section={section}/>;
              }
              return null;
            })}
          </div>
          <Footer/>
        </div>
      </>
    );
  }
  return null;
}

App.getInitialProps = async ({match, store}) => {
  if (store.getState().pages.page == null) {
    const page = await loadPagePromise(match.url);
    store.dispatch(setPage(page));
    for (let i = 0; i < page.sections.length; i++) {
      const section = page.sections[i];
      const component = sectionMap[section.type];
      if (component && component.hasOwnProperty('preload')) {
        await component.preload(store, section);
      }
    }
  }
};

export default App;
