import {SET_IMAGE} from '../reducers/images';
import firebase from '../../firebase';

export function setImage(path, url) {
  return {
    type: SET_IMAGE,
    path,
    url
  };
}

export function loadImagePromise(path) {
  return firebase.storage().ref(path).getDownloadURL();
}

export function loadImage(path) {
  return (dispatch, getState) => {
    const state = getState().images;
    if (state.loaded.filter(i => i.path === path).length < 1) {
      setImage(path, null);
      loadImagePromise(path)
        .then(url => dispatch(setImage(path, url)))
        .catch(() => dispatch(setImage(path, null)));
    }
  };
}
