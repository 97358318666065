import {SET_CONTACT_COMPLETE, SET_CONTACT_STARTED, SET_SCREEN} from '../reducers/analytics';
import firebase from '../../firebase';

export function setScreen(screen) {
  return (dispatch, getState) => {
    if (getState().analytics.screen !== screen) {
      firebase.analytics().setCurrentScreen(screen);
      firebase.analytics().logEvent('screen_view');
      dispatch({
        type: SET_SCREEN,
        screen
      });
    }
  };
}

export function setContactStarted(source) {
  return (dispatch, getState) => {
    if (!getState().analytics.contactStarted) {
      firebase.analytics().logEvent('contact_started', {source});
      dispatch({type: SET_CONTACT_STARTED});
    }
  }
}

export function setContactComplete() {
  return (dispatch, getState) => {
    if (!getState().analytics.contactStarted) {
      firebase.analytics().logEvent('contact_complete');
      dispatch({type: SET_CONTACT_COMPLETE});
    }
  }
}
