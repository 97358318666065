import 'react-app-polyfill/ie9';
import 'babel-polyfill';

import React from 'react';
import {hydrate} from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import configureStore from './store';
import {Provider} from 'react-redux';
import {ensureReady, After, getSerializedData} from '@jaredpalmer/after';
import routes from './routes';
import Analytics from './components/Analytics';

const preloadedState = getSerializedData('preloaded_state');
const store = configureStore(preloadedState);

function renderApp() {
  ensureReady(routes).then((data) =>
    hydrate(
      <BrowserRouter>
        <Provider store={store}>
          <Analytics />
          <After data={data} routes={routes} store={store} />
        </Provider>
      </BrowserRouter>,
      document.getElementById('root'),
    ),
  );
}

renderApp();

if (module.hot) {
  module.hot.accept('./routes', renderApp);
}
