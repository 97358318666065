export const SET_SCREEN = 'SET_SCREEN';
export const SET_CONTACT_STARTED = 'SET_CONTACT_STARTED';
export const SET_CONTACT_COMPLETE = 'SET_CONTACT_COMPLETE';

export default (
  state = {
    screen: null,
    contactStarted: false,
    contactComplete: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_SCREEN:
      return {
        ...state,
        screen: action.screen
      };
    case SET_CONTACT_STARTED:
      return {
        ...state,
        contactStarted: true
      };
    case SET_CONTACT_COMPLETE:
      return {
        ...state,
        contactComplete: true
      };
    default:
  }
  return state;
};
