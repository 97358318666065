import React from 'react';
import styles from './Split.module.scss';
import {useBackgroundImage} from '../hooks';

export default function Split({section}) {
  const image = useBackgroundImage(section.image);

  return (
    <div className={`${styles.split} ${section.direction === 'image-right' ? styles.imageRight : ''}`}>
      <div className={`${styles.image} ${section.coverImage ? styles.coverImage : ''}`} style={image} />
      <div className={styles.content}>
        <div>
          <h2>{section.heading}</h2>
          <div dangerouslySetInnerHTML={{__html: section.body }} />
        </div>
      </div>
    </div>
  );
}
