import React from 'react';
import styles from './Content.module.scss';

export default function Content({section}) {
  return (
    <div className={`${styles.content} ${section.emphasise ? styles.emphasise : (section.plain ? styles.plain : '')}`}>
      <div className={styles.container} dangerouslySetInnerHTML={{__html: section.body }} />
    </div>
  );
}
